import './Home.css';
import Carousel from '../components/Carousel'
import RoundedImg from '../components/RoundedImg';
import { useParams, useLoaderData, useOutletContext, Link } from 'react-router-dom';
import TestimonialCarousel from '../components/TestimonialCarousel';
import Parser from 'html-react-parser';

function Main() {
  const pageJSON = useOutletContext()
  const title = pageJSON ? pageJSON["home"]["title"] : null
  const content = pageJSON ? pageJSON["home"]["content"] : null
  const testimonials = pageJSON? pageJSON["testimonials"] : null
  const url_domain = pageJSON ? pageJSON["url_domain"] : null
  const class_pic_url = pageJSON ? pageJSON["home"]["class_pic_url"] : null
  const carousel_img_urls = pageJSON ? pageJSON["home"]["carousel_img_urls"] : null
  return (
    <div className="main">
      {Carousel(carousel_img_urls, url_domain)}
      <div className='center-content'>
        <div className="row-wrapper">
          <div className='left-col-wrapper col-element'>
            <div className="rounded-img-wrapper">
            <div className="class-pic-div">
                <img src={url_domain+class_pic_url} className='roundedImg'/>
              </div>
            </div>
          </div>
          <div className="right-col-wrapper col-element">
          <div className='row-title'>
              {title ? Parser(title) : null}
            </div>
            <div className="row-write-up">
              {content ? Parser(content) : null}
            </div>
            <button id='consult-btn' onClick={() => {
                window.location.href="https://api.whatsapp.com/send?phone=6598206604"
            }}>Request a <strong>FREE</strong> Consultation</button>
          </div>
        </div>
        <div className="row-wrapper">
          <h1 className='blue-underline'>Our Programmes</h1>
        </div>
        <div className="row-wrapper">
          <div className="link-grid-container">
            <div className="grid-link">
              <Link to='/preschool'>
                <RoundedImg src={process.env.PUBLIC_URL + "images/main-page/pre-school.png"}></RoundedImg>
              </Link>
            </div>
            <div className="grid-link">
              <Link to='/primary'>
                <RoundedImg src={process.env.PUBLIC_URL + "images/main-page/primary.png"}></RoundedImg>
              </Link>
            </div>
            <div className="grid-link">
              <Link to='/secondary'>
                <RoundedImg src={process.env.PUBLIC_URL + "images/main-page/secondary-school.png"}></RoundedImg>
              </Link>
            </div>
            <div className="grid-link">
              <Link to='/enrichment-classes'>
                <RoundedImg src={process.env.PUBLIC_URL + "images/main-page/enrichment-classes.png"}></RoundedImg>
              </Link>
            </div>
            <div className="grid-link">
              <Link to='/student-care'>
                <RoundedImg src={process.env.PUBLIC_URL + "images/main-page/student-care.png"}></RoundedImg>
              </Link>
            </div>
            <div className="grid-link">
              <Link to='/junior-college'>
                <RoundedImg src={process.env.PUBLIC_URL + "images/main-page/junior-college.png"}></RoundedImg>
              </Link>
            </div>
          </div>
        </div>
        <div className="row-wrapper">
          <h1 className='blue-underline'>Testimonials</h1>
        </div>
        {/* <div className="row-wrapper">
          <div id="testimonial-carousel" className="carousel slide">
            <div className="carousel-inner">
              {testimonials ? testimonials.map((testimonial, index) => {
                return (
                  <div className={index == 0 ? "carousel-item active" : "carousel-item"}>
                  <div className="testimonial-wrapper">
                    <div className="testimonial-div">
                      <div className="testimonial-text">
                        "{testimonial.content}"
                      </div>
                      <div className="testimonial-customer">
                        {testimonial.author}
                      </div>
                    </div>
                  </div>
                </div>
                )
              }) : null}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#testimonial-carousel" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#testimonial-carousel" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div> */}
      </div>
      <TestimonialCarousel testimonial_json={testimonials}/>
    </div>
  );
}
export default Main;