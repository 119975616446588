import './Navbar.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function Navbar() { 
  const [click,setClick] = useState(false)
  const [dropActive, setDropActive] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)
  const handleDrop = () => {
    if (window.innerWidth <= 960) {
      setDropActive(!dropActive)
    }
  }

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img className='logo-img' src='/images/sheng_academy_logo.png'></img>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <div className={dropActive ? 'dropdown nav-links active' : 'dropdown nav-links'}>
                <button className='dropbtn' onClick={handleDrop}>
                    Our Programmes 
                  <i className='fa fa-caret-down dropdown-icon'></i>
                </button>
                <div className='dropdown-content'>
                  <Link to='/preschool' className='dropdown-links' onClick={closeMobileMenu}>
                  Pre-School
                  </Link>
                  <Link to='/primary' className='dropdown-links' onClick={closeMobileMenu}>
                  Primary
                  </Link>
                  <Link to='/secondary' className='dropdown-links' onClick={closeMobileMenu}>
                  Secondary
                  </Link>
                  <Link to='/junior-college' className='dropdown-links' onClick={closeMobileMenu}>
                  Junior College
                  </Link>
                  <Link to='/enrichment-classes' className='dropdown-links' onClick={closeMobileMenu}>
                  Enrichment Classes
                  </Link>
                  <Link to='/student-care' className='dropdown-links' onClick={closeMobileMenu}>
                  Student Care
                  </Link>
                </div>
              </div> 
            </li>
            <li className='nav-item'>
              <Link
                to='/blog'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Blog
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/about-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                About Us
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;