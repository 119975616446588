import './RoundedImg.css';

interface RoundedImg{
  onClick?: () => void;
  src?: string;
};

const RoundedImg = ({ onClick, src } : RoundedImg) => {
  return (
    <img className='roundedImg' src={src} onClick={onClick} alt='image'></img>
  );
};

export default RoundedImg;