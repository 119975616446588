import { useEffect, useState } from 'react';
import { Link, Outlet, useOutletContext } from "react-router-dom";
import './Blog.css';
import Parser from 'html-react-parser'

function Blog() {
    const pageJSON = useOutletContext()
    const blogs : { id: "string", content: object }[] = pageJSON ? pageJSON["blogs"] : null
    const url_domain = pageJSON ? pageJSON["url_domain"] : null
    // useEffect(()=> {
    //     console.log(blogtest)
    //     console.log(loadTries)
    //     var results = sessionStorage.getItem('test');
    //     if(results){
    //         setblogtest(results);
    //     } else {
    //         setLoadTries(loadTries+1)
    //     }
    // }, [loadTries])
    return (
        <div className="main">
            <div id='banner'>
                <img src='images/page-banners/blog-banner.png' id='banner-img'></img>
                <div id='banner-title'>Blog</div>
            </div>
            <div className='center-content'>
                <div className="row-wrapper">
                    <h1 id='blog-title' className='blue-underline'>Blogs</h1>
                </div>

                {blogs ? blogs.map((blog, index) => {
                    var blog_id = blog["id"]
                    return (
                        <div className="row-wrapper blog-wrapper" id={`blog-${blog_id}`} key={blog_id}>
                            <div className="blog">
                            <Link to={`/blog/${blog_id}`}>
                                    {/* <img src="/placeholder.jpg" alt="" className='blog-img'/> */}
                                    <img src={url_domain+blog["blogThumbnailURL"]} alt="" className='blog-img'/>
                                </Link>
                                <div className="blog-text-wrapper">
                                    <Link to={`/blog/${blog_id}`} className='blog-title-link'>
                                        {/* <h3 className='blog-text-title underline'>{blog["content"]["title"]}</h3> */}
                                        {Parser(blog["content"]["title"])}
                                    </Link>
                                    {Parser(blog["content"]["description"])}
                                    {/* <p className='blog-text'>{blog["content"]["description"]}</p> */}
                                </div>
                            </div>
                        </div>
                    )
                }) : null}
            </div>
        </div>
    );
};

export default Blog;