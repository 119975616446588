import { useOutletContext } from 'react-router-dom'
import './Enrichment.css'
import { useRef, useEffect } from 'react'
import Breadcrumbs from '../components/Breadcrumbs'

function Enrichment() {
    const pageJSON = useOutletContext()
    const url_domain = pageJSON ? pageJSON["url_domain"] : null
    const enrichment_classes = pageJSON ? pageJSON["enrichment"] : null
    const classesRef = useRef([]);

    useEffect(() => {
        if (pageJSON) {
            classesRef.current = classesRef.current.slice(0, enrichment_classes.length);
        }
    }, [pageJSON])
    
    return (
        <div className="main">
            <div id="banner">
                <img src='images/page-banners/enrichment_banner.png' id='banner-img'></img>
                <div id='banner-title'>Enrichment Classes</div>
            </div>
            <div className="center-content">
                <Breadcrumbs/>
                {enrichment_classes ? enrichment_classes.map((enrichment_class, index) => {
                    return (
                        <div className="enrichment_class learn-more-hidden" ref={el => classesRef.current[index] = el}>
                            <img src={url_domain+enrichment_class.pic_1_url} alt="" />
                            <button className='learn-more-btn' onClick={() => {
                                classesRef.current[index].classList.toggle("learn-more-hidden")
                            }}>
                                <i className="fa-solid fa-caret-up"></i><p>Learn More</p><i className="fa-solid fa-caret-up"></i>
                            </button>
                            <div className="enrich-learn-more-div">
                                <img src={url_domain+enrichment_class.pic_2_url} alt="" />
                            </div>
                        </div>
                    )
                }) : null}
                <div className="row-wrapper">
                    <button id='contact-us-btn' onClick={() => {
                        window.location.href="https://api.whatsapp.com/send?phone=6598206604"
                    }}>
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Enrichment;