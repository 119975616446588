import './BlogPost.css';
import Breadcrumbs from '../components/Breadcrumbs';
import { Link, useOutletContext } from "react-router-dom";
import { useParams } from 'react-router-dom';
import RoundedImg from '../components/RoundedImg';
import Parser from 'html-react-parser';

function BlogPost() {
    const pageJSON = useOutletContext()
    const url_domain = pageJSON ? pageJSON["url_domain"] : null
    const blogs : { id: "string", content: object }[] = pageJSON ? pageJSON["blogs"] : null
    const {id} = useParams()
    const current_blog = blogs ? blogs.find(blog => blog.id === id) : null
    const next_blog = current_blog ? blogs[(blogs.indexOf(current_blog) + 1) % blogs.length] : null

    console.log(next_blog)
    console.log(blogs)
    console.log(id)

    return (
        <div className="main">
            <div className='center-content'>
                <Breadcrumbs></Breadcrumbs>
                <div id='blog-post-wrapper'>
                    <div className="blog-title-wrapper">
                        {current_blog ? Parser(current_blog["content"]["title"]) : null}
                    </div>
                    <img src={current_blog ? url_domain+current_blog["bannerURL"] : null} id='blog-post-img'/>
                    <div className="blog-content-wrapper">
                        {current_blog ? Parser(current_blog["content"]["post-content"]) : null}
                    </div>
                </div>
                <div className="read-more">
                    <h2>Read More</h2>
                    {
                        next_blog ?
                        <div className="blog next-blog">
                            <Link to={`/blog/${next_blog['id']}`}>
                                <img src={url_domain+next_blog["blogThumbnailURL"]} alt="" className='blog-img'/>
                            </Link>
                            <div className="blog-text-wrapper">
                                <Link to={`/blog/${next_blog['id']}`} className='blog-title-link'>
                                    {Parser(next_blog["content"]["title"])}
                                </Link>
                                {Parser(next_blog["content"]["description"])}
                            </div>
                        </div> :
                        null
                    }
                </div>
                <div className="programmes-div">
                    <h2>Check Out Our Programmes</h2>
                    <div className="link-grid-container">
                        <div className="grid-link">
                        <Link to='/pre-school'>
                            <RoundedImg src={process.env.PUBLIC_URL + "/images/main-page/pre-school.png"}></RoundedImg>
                        </Link>
                        </div>
                        <div className="grid-link">
                        <Link to='/primary'>
                            <RoundedImg src={process.env.PUBLIC_URL + "/images/main-page/primary.png"}></RoundedImg>
                        </Link>
                        </div>
                        <div className="grid-link">
                        <Link to='/secondary'>
                            <RoundedImg src={process.env.PUBLIC_URL + "/images/main-page/secondary-school.png"}></RoundedImg>
                        </Link>
                        </div>
                        <div className="grid-link">
                        <Link to='/enrichment-classes'>
                            <RoundedImg src={process.env.PUBLIC_URL + "/images/main-page/enrichment-classes.png"}></RoundedImg>
                        </Link>
                        </div>
                        <div className="grid-link">
                        <Link to='/student-care'>
                            <RoundedImg src={process.env.PUBLIC_URL + "/images/main-page/student-care.png"}></RoundedImg>
                        </Link>
                        </div>
                        <div className="grid-link">
                        <Link to='/junior-college'>
                            <RoundedImg src="/images/main-page/junior-college.png"></RoundedImg>
                        </Link>
                        </div>
                    </div>              
                </div>
            </div>
        </div>
    );
};

export default BlogPost;