import { useEffect, useRef } from 'react';
import './JuniorCollege.css';
import { useOutletContext } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import Parser from 'html-react-parser';

function JuniorCollege() {
    const pageJSON = useOutletContext()
    const subjectsRef = useRef(null)
    const mathElem = useRef(null)
    const chemElem = useRef(null)
    const phyElem = useRef(null)
    const url_domain = pageJSON ? pageJSON["url_domain"] : null
    const htmlElement = document.documentElement

    useEffect(() => {
        htmlElement.style.setProperty('--BLUE-elem-height', mathElem.current.offsetHeight + 'px');
        htmlElement.style.setProperty('--CHEMISTRY-elem-height', chemElem.current.offsetHeight + 'px');
        htmlElement.style.setProperty('--GRAY-elem-height', phyElem.current.offsetHeight + 'px');

        const observer = new IntersectionObserver(
            subjects => {
                for (let i = subjects.length - 1; i >= 0; i--) {
                    const subject = subjects[i]
                    console.log(subject.target.getBoundingClientRect().height)
                    if (subject.isIntersecting) {
                        subject.target.classList.add("opened")
                        observer.unobserve(subject.target)
                    }
                }
            },
            {
                "rootMargin": "-0px 0px -250px 0px",
                "threshold": 0.1
            }
        );

        const resize_observer = new ResizeObserver(
            subjects => {
                for (let i = subjects.length - 1; i >= 0; i--) {
                    const subject = subjects[i]
                    htmlElement.style.setProperty('--'+subject.target.classList[0]+'-elem-height', subject.target.getBoundingClientRect().height + 'px');
                }
            }
        );
        
        subjectsRef.current.querySelectorAll(".subject-contents-wrapper:not(.BLUE)").forEach((subject) => {
            observer.observe(subject)
        })
        subjectsRef.current.querySelectorAll(".subject-contents-wrapper").forEach((subject) => {
            resize_observer.observe(subject)
        })
        return () => observer.disconnect();
    }, [])

    return (
        <div className="main">
            <div id='banner'>
                <img src='images/page-banners/junior-college-banner.png' id='banner-img'/>
                <div id='banner-title'>Junior College</div>
            </div>

            <div className='center-content'>
                <Breadcrumbs></Breadcrumbs>
                <div id='subject-stack-wrapper' ref={subjectsRef}>
                    <div className='BLUE subject-contents-wrapper learn-more-hidden' id='math-sub' ref={mathElem}>
                        <div className="subjects BLUE subject-content-subject">
                            <h2>MATH</h2>
                        </div>
                        <div className="row-wrapper">
                            <div className='subject-content'>
                                <div className="subject-row grid">
                                    <div className="subject-row-image">
                                        <img src={pageJSON ? url_domain+pageJSON["junior-college"]["MATH"]["img_url"] : null}></img>
                                    </div>
                                    <div className="subject-row-right">
                                        <div className="subject-title">
                                            {pageJSON ? Parser(pageJSON["junior-college"]["MATH"]["title"]) : null}
                                        </div>
                                        <div className="subject-tutors">
                                            <p>Head Tutors:</p>
                                            <div className="subject-tutors-imgs">
                                                {pageJSON ? pageJSON["junior-college"]["MATH"]["tutor_img_urls"].map((img_url, index) => {
                                                    return(<img src={url_domain+img_url} alt="" />)
                                                }) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="subject-row">
                                    <div className="subject-desc">
                                        {pageJSON ? Parser(pageJSON["junior-college"]["MATH"]["content"]) : null}
                                    </div>
                                </div>
                                <div className="subject-row">
                                    <button className='learn-more-btn' onClick={() => {
                                        mathElem.current.classList.toggle("learn-more-hidden")
                                    }}>
                                        <i className="fa-solid fa-caret-up"></i><p>Learn More</p><i className="fa-solid fa-caret-up"></i>
                                    </button>
                                </div>
                                <div className="learn-more-div">
                                    <div className="learn-more-text">
                                        {pageJSON ? Parser(pageJSON["junior-college"]["MATH"]["learn_more"]) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='GREEN subject-contents-wrapper learn-more-hidden' id='science-sub' ref={chemElem}>
                        <div className="subjects GREEN subject-content-subject">
                            <h2>CHEMISTRY</h2>
                        </div>
                        <div className="row-wrapper">
                            <div className='subject-content'>
                            <div className="subject-row grid">
                                    <div className="subject-row-image">
                                        <img src={pageJSON ? url_domain+pageJSON["junior-college"]["CHEMISTRY"]["img_url"] : null}></img>
                                    </div>
                                    <div className="subject-row-right">
                                        <div className="subject-title">
                                            {pageJSON ? Parser(pageJSON["junior-college"]["CHEMISTRY"]["title"]) : null}
                                        </div>
                                        <div className="subject-tutors">
                                            <p>Head Tutors:</p>
                                            <div className="subject-tutors-imgs">
                                                {pageJSON ? pageJSON["junior-college"]["CHEMISTRY"]["tutor_img_urls"].map((img_url, index) => {
                                                    return(<img src={url_domain+img_url} alt="" />)
                                                }) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="subject-row">
                                    <div className="subject-desc">
                                        {pageJSON ? Parser(pageJSON["junior-college"]["CHEMISTRY"]["content"]) : null}
                                    </div>
                                </div>
                                <div className="subject-row">
                                    <button className='learn-more-btn' onClick={() => {
                                        chemElem.current.classList.toggle("learn-more-hidden")
                                    }}>
                                        <i className="fa-solid fa-caret-up"></i><p>Learn More</p><i className="fa-solid fa-caret-up"></i>
                                    </button>
                                </div>
                                <div className="learn-more-div">
                                    <div className="learn-more-text">
                                        {pageJSON ? Parser(pageJSON["junior-college"]["CHEMISTRY"]["learn_more"]) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='GRAY subject-contents-wrapper learn-more-hidden' id='chinese-sub' ref={phyElem}>
                        <div className="subjects GRAY subject-content-subject">
                            <h2>PHYSICS</h2>
                        </div>
                        <div className="row-wrapper">
                            <div className='subject-content'>
                            <div className="subject-row grid">
                                    <div className="subject-row-image">
                                        <img src={pageJSON ? url_domain+pageJSON["junior-college"]["PHYSICS"]["img_url"] : null}></img>
                                    </div>
                                    <div className="subject-row-right">
                                        <div className="subject-title">
                                            {pageJSON ? Parser(pageJSON["junior-college"]["PHYSICS"]["title"]) : null}
                                        </div>
                                        <div className="subject-tutors">
                                            <p>Head Tutors:</p>
                                            <div className="subject-tutors-imgs">
                                                {pageJSON ? pageJSON["junior-college"]["PHYSICS"]["tutor_img_urls"].map((img_url, index) => {
                                                    return(<img src={url_domain+img_url} alt="" />)
                                                }) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="subject-row">
                                    <div className="subject-desc">
                                        {pageJSON ? Parser(pageJSON["junior-college"]["PHYSICS"]["content"]) : null}
                                    </div>
                                </div>
                                <div className="subject-row">
                                    <button className='learn-more-btn' onClick={() => {
                                        phyElem.current.classList.toggle("learn-more-hidden")
                                    }}>
                                        <i className="fa-solid fa-caret-up"></i><p>Learn More</p><i className="fa-solid fa-caret-up"></i>
                                    </button>
                                </div>
                                <div className="learn-more-div">
                                    <div className="learn-more-text">
                                        {pageJSON ? Parser(pageJSON["junior-college"]["PHYSICS"]["learn_more"]) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-wrapper">
                        <button id='contact-us-btn' onClick={() => {
                            window.location.href="https://api.whatsapp.com/send?phone=6598206604"
                        }}>
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JuniorCollege;