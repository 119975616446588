import { Link } from 'react-router-dom'
import './Breadcrumbs.css'
import { useLocation } from 'react-router'

function clean_word(word) {
    var words = word.split("-")
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    
    return words.join(" ");
}

export default function Breadcrumbs() {
    const path_list = useLocation().pathname.split("/")
    // console.log(path_list)
    return (
        <div className='breadcrumbs'>
            {/* {path_list.map((location, index) => 
            {
            <Link to={location}><p>{location == "" ? "Home" : clean_word(location)}</p></Link>})} */}

            {path_list.map((location, index : number) => {
                // console.log(location)
                return (
                    index+1 == path_list.length ? <><i className="fa-solid fa-angle-right breadcrumbs-arrow"></i><a className='breadcrumbs-links'>{location == "" ? "Home" : clean_word(location)}</a></> :
                    index > 0 ? <><i className="fa-solid fa-angle-right breadcrumbs-arrow"></i><Link to={`/${location}`} className='breadcrumbs-links'>{location == "" ? "Home" : clean_word(location)}</Link></> :
                    <Link to={`/${location}`} className='breadcrumbs-links'>{location == "" ? "Home" : clean_word(location)}</Link>
                )
            })}
        </div>
    )
}