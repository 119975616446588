import { useEffect, useRef, useState } from 'react';
import './StudentCare.css';
import Breadcrumbs from '../components/Breadcrumbs';
import { Link, useOutletContext } from 'react-router-dom';

function StudentCare() { 
    const pageJSON = useOutletContext()
    const videoURL = pageJSON ? pageJSON["student-care"]["video_url"] : null
    const infographicURL = pageJSON ? pageJSON["student-care"]["infographic_url"] : null
    const url_domain = pageJSON ? pageJSON["url_domain"] : null
    
    return(
        <div className="main">
            <div id='banner'>
                <img src='images/page-banners/student-care-banner.png' id='banner-img'></img>
                <div id='banner-title'>Student Care</div>
            </div>
            <div className="center-content">
                <Breadcrumbs/>
                <div className="row-wrapper">
                    <h2>Our Adventures</h2>
                </div>
                <div className="row-wrapper student-care-wrapper">
                    <iframe src={videoURL} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen id='student-care-video'></iframe>
                </div>
                <div className="row-wrapper student-care-infographic-wrapper">
                    <img src={url_domain+infographicURL} alt="" id='student-care-infographic'/>
                    {/* <div className="brochure-wrapper">
                        <div className="brochure-title">
                            <h1>Weekdays: 1pm to 7pm</h1>
                        </div>
                        <div className="brochure-row">
                            <div className="brochure-icon">
                                <img src="/images/student-care/homework-supervision.png" alt="" />
                            </div>
                            <div className="brochure-text">
                                <h4>Homework supervision by <span className='blue-text'>high experienced tutors</span> with <span className='blue-text'>personalized Student-Teacher attention</span></h4>
                            </div>
                        </div>
                        <div className="brochure-row">
                            <div className="brochure-icon">
                                <img src="/images/student-care/healthy-meals.png" alt="" />
                            </div>
                            <div className="brochure-text">
                                <h4>Healthy Meals & Warm Showers provided</h4>
                            </div>
                        </div>
                        <div className="brochure-row">
                            <div className="brochure-icon">
                                <img src="/images/student-care/messaging.png" alt="" />
                            </div>
                            <div className="brochure-text">
                                <h4><span className='blue-text'>Close partnership with parents</span> to support child's total <span className='blue-text'>wellbeing & development</span></h4>
                            </div>
                        </div>
                        <div className="brochure-row">
                            <div className="brochure-icon">
                                <img src="/images/student-care/school.png" alt="" />
                            </div>
                            <div className="brochure-text">
                                <h4>Fetch from Gongshang Primary, Tampines North Primary & selected drop-off points</h4>
                            </div>
                        </div>
                        <div className="brochure-row">
                            <div className="brochure-icon">
                                <img src="/images/student-care/rebate.png" alt="" />
                            </div>
                            <div className="brochure-text">
                                <h4><span className='blue-text'>Rebates</span> given to join enrichment courses & tuition</h4>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row-wrapper">
                    <button id='contact-us-btn' onClick={() => {
                        window.location.href="https://api.whatsapp.com/send?phone=6592350468"
                    }}>
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
    )
};

export default StudentCare;