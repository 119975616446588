import { useOutletContext } from 'react-router-dom'
import Breadcrumbs from '../components/Breadcrumbs'
import './AboutUs.css'
import Parser from 'html-react-parser'

function AboutUs() {
    const pageJSON = useOutletContext()
    const url_domain = pageJSON ? pageJSON["url_domain"] : null

    return (
        <div className="main">
            <div id='banner'>
                <img src='images/page-banners/about-us-banner.png' id='banner-img'></img>
                <div id='banner-title'>About Us</div>
            </div>
            <div className="center-content">
                <Breadcrumbs/>
                <div className="row-wrapper title">
                    <h2 className='text-title'>About Us</h2>
                </div>
                <div>
                    {pageJSON ? Parser(pageJSON["about-us"]["about-us-desc"]) : null}
                </div>
                <div className="row-wrapper title">
                    <h2 className='text-title'>Our Tutors</h2>
                </div>
                <div className="row-wrapper">
                    <div className="tutors-div">
                        {pageJSON ? pageJSON["about-us"]["tutors"].map((tutor,index) => {
                            return(
                                <div className="tutor-div">
                                    <div className="tutor-img">
                                        <img src={url_domain+tutor.tutor_img_url} alt="" />
                                    </div>
                                    <div className="tutor-info">
                                        <div className="tutor-title">
                                            <h4>{tutor.tutor_name}</h4>
                                        </div>
                                        <div className="tutor-desc">
                                            <p>{Parser(tutor.tutor_desc)}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs