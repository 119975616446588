import { Link } from 'react-router-dom';
import './Footer.css'

function Footer() {
    return (
        <footer>
            <div className='footer-wrapper'>
                <div className="footer-col" id='icon-col'>
                    <img src="/images/footer/footer.png"/>
                </div>
                <div className="footer-col">
                    <div className="footer-col-content-wrapper">
                    <h2>Navigate</h2>
                        <div className="footer-col-content">
                            <div className="footer-col-content-col">
                                <Link to='/' className='footer-link'>
                                    Home
                                </Link>
                                <Link to='/blog' className='footer-link'>
                                    Blog
                                </Link>
                                <Link to='/about-us' className='footer-link'>
                                    About Us
                                </Link>
                                <Link to='/contact-us' className='footer-link'>
                                    Contact Us
                                </Link>
                            </div>
                            <div className="footer-col-content-col">
                                <Link to='/preschool' className='footer-link'>
                                    Pre School
                                </Link>
                                <Link to='/primary' className='footer-link'>
                                    Primary School
                                </Link>
                                <Link to='/secondary' className='footer-link'>
                                    Secondary School
                                </Link>
                                <Link to='/junior-college' className='footer-link'>
                                    Junior College
                                </Link>
                                <Link to='/enrichment-classes' className='footer-link'>
                                    Enrichment Class
                                </Link>
                                <Link to='/student-care' className='footer-link'>
                                    Student Care
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-col">
                    <div className="footer-col-content-wrapper">
                    <h2>Contact Us</h2>
                        <div className="footer-col-content">
                            <div className="footer-col-content-row">
                                <Link to='https://api.whatsapp.com/send?phone=6598206604' className='footer-icon'>
                                    <i className="fa-brands fa-whatsapp fa-xl"></i>
                                </Link>
                                <Link to='mailto:kchengooi@gmail.com' className='footer-icon'>
                                    <i className="fa-solid fa-envelope fa-lg"></i>
                                </Link>
                                <Link to='https://bit.ly/3QS5fTe' className='footer-icon'>
                                    <i className="fa-brands fa-facebook fa-lg"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;