import { useEffect, useRef, useState } from 'react'
import './TestimonialCarousel.css'
import { Link } from 'react-router-dom'

function TestimonialCarousel(testimonial_json) {
  const testimonial_list = testimonial_json ? testimonial_json['testimonial_json'] : null
  const maxListIndex = testimonial_list ? testimonial_list.length-1 : 0
  const testimonial_ref = useRef(null)
  const [testimonialElements, setTestimonialElements] = useState(Array<HTMLElement>)
  const [currentIndex, setCurrentIndex] = useState(1)
  const [currentListIndex, setCurrentListIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(0)
  const [timedOut, setTimedOut] = useState(false)
  let touchstartX = 0
  let touchendX = 0

  useEffect(() => {
    setTestimonialElements(testimonial_ref.current.querySelectorAll(".testimonial-div"))
  }, [])

  useEffect(() => {
    setMaxIndex(testimonialElements.length-1)
    if (testimonialElements.length > 0 && testimonial_list != null) {
      testimonialElements[0].childNodes[0].textContent = testimonial_list[maxListIndex].content
      testimonialElements[0].childNodes[1].textContent = testimonial_list[maxListIndex].author
      testimonialElements[1].childNodes[0].textContent = testimonial_list[0].content
      testimonialElements[1].childNodes[1].textContent = testimonial_list[0].author
      testimonialElements[2].childNodes[0].textContent = testimonial_list[1].content
      testimonialElements[2].childNodes[1].textContent = testimonial_list[1].author
    }
  }, [testimonialElements, testimonial_list])
  
  function next_testimonial() {
    if (!timedOut) {
      let rightIndex = currentIndex == 0 ? maxIndex : currentIndex - 1
      let newCurrentIndex = currentIndex == maxIndex ? 0 : currentIndex + 1
      let newCurrentListIndex = currentListIndex == maxListIndex ? 0 : currentListIndex + 1
      let newRightListIndex = (currentListIndex + 2) > maxListIndex ? (currentListIndex + 1) - maxListIndex : currentListIndex + 2
      testimonialElements[newCurrentIndex].classList.remove('testimonial-right','wrap')
      testimonialElements[newCurrentIndex].classList.add('testimonial-current')
      testimonialElements[currentIndex].classList.remove('testimonial-current')
      testimonialElements[currentIndex].classList.add('testimonial-left')
      testimonialElements[rightIndex].classList.remove('testimonial-left')
      testimonialElements[rightIndex].classList.add('testimonial-right','wrap')
      testimonialElements[rightIndex].childNodes[0].textContent = testimonial_list[newRightListIndex].content
      testimonialElements[rightIndex].childNodes[1].textContent = testimonial_list[newRightListIndex].author
      setCurrentIndex(newCurrentIndex)
      setCurrentListIndex(newCurrentListIndex)
      setTimedOut(true)
      setTimeout(function(){setTimedOut(false)},1000);
    }
  }

  function prev_testimonial() {
    if (!timedOut) {
      let leftIndex = currentIndex == maxIndex ? 0 : currentIndex + 1
      let newCurrentIndex = currentIndex == 0 ? maxIndex : currentIndex - 1
      let newCurrentListIndex = currentListIndex == 0 ? maxListIndex : currentListIndex - 1
      let newLeftListIndex = (currentListIndex - 2) < 0 ? maxListIndex + (currentListIndex - 1) : currentListIndex - 2
      testimonialElements[newCurrentIndex].classList.remove('testimonial-left','wrap')
      testimonialElements[newCurrentIndex].classList.add('testimonial-current')
      testimonialElements[currentIndex].classList.remove('testimonial-current')
      testimonialElements[currentIndex].classList.add('testimonial-right')
      testimonialElements[leftIndex].classList.remove('testimonial-right')
      testimonialElements[leftIndex].classList.add('testimonial-left','wrap')
      testimonialElements[leftIndex].childNodes[0].textContent = testimonial_list[newLeftListIndex].content
      testimonialElements[leftIndex].childNodes[1].textContent = testimonial_list[newLeftListIndex].author
      setCurrentIndex(newCurrentIndex)
      setCurrentListIndex(newCurrentListIndex)
      setTimedOut(true)
      setTimeout(function(){setTimedOut(false)},1000);
    }
  }

  function checkDirection(touchendX, touchstartX) {
    if (touchendX+20 < touchstartX) {
      next_testimonial()
    }
    if (touchendX-20 > touchstartX) {
      prev_testimonial()
    }
  }

  return (
    <div id="testimonial-carousel">
      <div className="testimonial-wrapper" ref={testimonial_ref}>
        {/* <div className="testimonial-div testimonial-left" id="testimonial-yellow" ref={el => testimonialDivRefs.current[0] = el}> */}
        {/* <div className="testimonial-div testimonial-left" id="testimonial-yellow" ref={testRef}> */}
        <div className="testimonial-div testimonial-left" id="testimonial-yellow" 
        onTouchStart={e => {touchstartX = e.changedTouches[0].screenX}}
        onTouchEnd={e => {touchendX = e.changedTouches[0].screenX
          checkDirection(touchendX, touchstartX)
        }}>
          <div className="testimonial-text">
            Loading
          </div>
          <div className="testimonial-customer">
          </div>
        </div>
        {/* <div className="testimonial-div testimonial-current" id="testimonial-blue" ref={test2Ref}> */}
        <div className="testimonial-div testimonial-current" id="testimonial-blue"
        onTouchStart={e => {touchstartX = e.changedTouches[0].screenX}}
        onTouchEnd={e => {touchendX = e.changedTouches[0].screenX
          checkDirection(touchendX, touchstartX)
        }}>
          <div className="testimonial-text">
          Loading
          </div>
          <div className="testimonial-customer">
          </div>
        </div>
        {/* <div className="testimonial-div testimonial-right" id="testimonial-green" ref={el => testimonialDivRefs.current[2] = el}> */}
        <div className="testimonial-div testimonial-right" id="testimonial-green"
        onTouchStart={e => {touchstartX = e.changedTouches[0].screenX}}
        onTouchEnd={e => {touchendX = e.changedTouches[0].screenX
          checkDirection(touchendX, touchstartX)
        }}>
          <div className="testimonial-text">
          Loading
          </div>
          <div className="testimonial-customer">
          </div>
        </div>
        </div>
        <button id="testimonial-carousel-prev" type="button" className='testimonial-prev-next' onClick={prev_testimonial}>
            <i className="fa-solid fa-chevron-left fa-lg"/>
        </button>
        <button id="testimonial-carousel-next" type="button" className='testimonial-prev-next' onClick={next_testimonial}>
            <i className="fa-solid fa-chevron-right fa-lg"/>
        </button>
    </div>
  )
}

export default TestimonialCarousel