import './ContactUs.css';
import Breadcrumbs from '../components/Breadcrumbs';
import {APIProvider, AdvancedMarker, InfoWindow, Map, useAdvancedMarkerRef, useMap, Pin} from '@vis.gl/react-google-maps';
import { useRef, useState } from 'react';

function ContactUs() {
    const [markerRef1, marker1] = useAdvancedMarkerRef();
    const [markerRef2, marker2] = useAdvancedMarkerRef();
    const [markerRef3, marker3] = useAdvancedMarkerRef();
    const [infowindowShown1, setInfowindowShown1] = useState<boolean>(false);
    const [infowindowShown2, setInfowindowShown2] = useState<boolean>(false);
    const [infowindowShown3, setInfowindowShown3] = useState<boolean>(false);
    const infowindowList = [setInfowindowShown1,setInfowindowShown2,setInfowindowShown3]
    const [lat, setLat] = useState<number>(1.3561886169621218)
    const [lng, setLng] = useState<number>(103.95363063384508)
    const [zoom, setZoom] = useState<number>(15)
    
    function toggleInfoWindow(set_function) {
        infowindowList.forEach(toggleFunc => {
            if(toggleFunc != set_function) {
                toggleFunc(false)
            }
        });
        set_function(previousState => !previousState);
    }
    function closeInfoWindow(set_function) {
        set_function(false);
    }
    function link_click(lat:number, lng:number){
        setLat(lat);
        setLng(lng);
        setZoom(18);
    }
    return (
        <div className="main">
            <div id='banner'>
                <img src='images/page-banners/contact-us-banner.png' id='banner-img'/>
                <div id='banner-title'>Contact Us</div>
            </div>
            <div className='center-content'>
                <Breadcrumbs></Breadcrumbs>
                <div className="row-wrapper">
                    <h1>Contact Us</h1>
                </div>
                <div className="row-wrapper contact-row">
                    <div className='col-element link-wrapper contact-link' onClick={() => {
                        window.location.href="https://api.whatsapp.com/send?phone=6598206604"
                    }}>
                        <img className='contact-us-icons' src="images/contact-us/phone-icon.png"/>
                        <div className="contact-us-num-right">
                            <h5>+65 9820 6604</h5>
                            <h5>Sheng Academy @313</h5>
                        </div>
                    </div>
                    <div className='col-element link-wrapper contact-link' onClick={() => {
                        window.location.href="https://api.whatsapp.com/send?phone=6592350468"
                    }}>
                        <img className='contact-us-icons' src="images/contact-us/phone-icon.png"/>
                        <div className="contact-us-num-right">
                            <h5>+65 9235 0468</h5>
                            <h5>Sheng Academy @477</h5>
                        </div>
                    </div>
                    <div className='col-element link-wrapper contact-link' onClick={() => {
                        window.location.href="mailto:shengacademy@gmail.com"
                    }}>
                        <img className='contact-us-icons' src="images/contact-us/email-icon.png"/>
                        <h5>shengacademy<wbr/>@gmail.com</h5>
                    </div>
                    <div className='col-element link-wrapper contact-link' onClick={() => {
                        window.location.href="https://bit.ly/3QS5fTe"
                    }}>
                        <img className='contact-us-icons' src="images/contact-us/facebook-icon.png"/>
                        <h5>https://bit.ly/3QS5fTe</h5>
                    </div>
                </div>
                <div className="row-wrapper">
                    <h1>Find Us</h1>
                </div>
                <div className="row-wrapper contact-row">
                    <div className='col-element link-wrapper' onClick={()=>{toggleInfoWindow(setInfowindowShown1);link_click(1.35206,103.95695)}}>
                        <img className='contact-us-icons' src="images/contact-us/green-marker.png"/>
                        <div className="link-text-content">
                            <h5>Sheng Academy @313</h5>
                            <p>Blk 313, Tampines St 33, #01-26</p>
                        </div>
                    </div>
                    <div className='col-element link-wrapper' onClick={()=>{toggleInfoWindow(setInfowindowShown2);link_click(1.36101,103.95278)}}>
                        <img className='contact-us-icons' src="images/contact-us/yellow-marker.png"/>
                        <div className="link-text-content">
                            <h5>Sheng Academy @477</h5>
                            <p>Blk 477, Tampines St 43, #02-184</p>
                        </div>
                    </div>
                    <div className='col-element link-wrapper' onClick={()=>{toggleInfoWindow(setInfowindowShown3);link_click(1.35906,103.95108)}}>
                        <img className='contact-us-icons' src="images/contact-us/blue-marker.png"/>
                        <div className="link-text-content">
                            <h5>Sheng Academy @433</h5>
                            <p>Blk 433, Tampines St 43, #01-73</p>
                        </div>
                    </div>
                </div>
                <APIProvider apiKey='AIzaSyAD7_QVwsNzzIpWjvQakdnmJCfq4syz5-Q'>
                    <div id='map'>
                        <Map defaultZoom={15} 
                        defaultCenter={{lat: 1.3561886169621218, lng: 103.95363063384508}} 
                        gestureHandling={'cooperative'} 
                        mapId='85602a5960a0f0bb' 
                        center={{lat: lat, lng: lng}} 
                        zoom={zoom} 
                        onZoomChanged={ev => setZoom(ev.detail.zoom)}
                        onCameraChanged={ev => {setLat(ev.detail.center.lat);
                        setLng(ev.detail.center.lng);}}
                        >
                            <AdvancedMarker ref={markerRef1} onClick={()=>toggleInfoWindow(setInfowindowShown1)} position={{lat: 1.35206, lng: 103.95695}}>
                                <Pin background={'#5ec4b7'} glyphColor={'#ffffff'} borderColor={'#5ec4b7'} />
                            </AdvancedMarker>
                            <AdvancedMarker ref={markerRef2} onClick={()=>toggleInfoWindow(setInfowindowShown2)} position={{lat: 1.36101, lng: 103.95278}}>
                                <Pin background={'#fdbd41'} glyphColor={'#ffffff'} borderColor={'#fdbd41'} />
                            </AdvancedMarker>
                            <AdvancedMarker ref={markerRef3} onClick={()=>toggleInfoWindow(setInfowindowShown3)} position={{lat: 1.35906, lng: 103.95108}}>
                                <Pin background={'#405caa'} glyphColor={'#ffffff'} borderColor={'#405caa'} />
                            </AdvancedMarker>
                            {infowindowShown1 && (
                            <InfoWindow anchor={marker1} onCloseClick={()=>closeInfoWindow(setInfowindowShown1)}>
                                <div className="info-window">
                                    <img src="images/contact-us/313.jpg" className='info-img'/>
                                    <a href="https://maps.app.goo.gl/2UtD9rKR13qwRDZA8" target="_blank" rel="noopener noreferrer">Sheng Academy @313 (Tuition)</a>
                                    <p>Operating hours<br/>Monday to Friday: 3pm to 8:30pm<br/>Saturday & Sunday: 9am to 2pm</p>
                                </div>
                            </InfoWindow>
                            )}
                            {infowindowShown2 && (
                            <InfoWindow anchor={marker2} onCloseClick={()=>closeInfoWindow(setInfowindowShown2)}>
                                <div className="info-window">
                                    <img src="images/contact-us/477.jpg" className='info-img'/>
                                    <a href="https://maps.app.goo.gl/BQqyp4UVhHmWt3n99" target="_blank" rel="noopener noreferrer">Sheng Academy @477 (Tuition)</a>
                                    <p>Operating hours<br/>Monday to Friday: 3pm to 8:30pm<br/>Saturday & Sunday: 9am to 2pm</p>
                                </div>
                            </InfoWindow>
                            )}
                            {infowindowShown3 && (
                            <InfoWindow anchor={marker3} onCloseClick={()=>closeInfoWindow(setInfowindowShown3)}>
                                <div className="info-window">
                                    <img src="images/contact-us/433.jpg" className='info-img'/>
                                    <a href="https://maps.app.goo.gl/nMFX6ArjRbCnDT3X8" target="_blank" rel="noopener noreferrer">Sheng Academy @433 (Student Care)</a>
                                    <p>Operating hours<br/>Monday to Friday: 12pm to 7pm<br/>Closed on weekends, public holidays and observed public holidays</p>
                                </div>
                            </InfoWindow>
                            )}
                        </Map>
                    </div>
                </APIProvider>
            </div>
        </div>
    );
}

export default ContactUs;