import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import Home from './pages/Home';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import StudentCare from './pages/StudentCare'
import Secondary from './pages/Secondary'
import Preschool from "./pages/Preschool";
import Primary from "./pages/Primary";
import JuniorCollege from "./pages/JuniorCollege";
import Enrichment from "./pages/Enrichment";
import Blog from './pages/Blog'
import BlogPost from './pages/BlogPost'
import ContactUs from './pages/ContactUs'
import AboutUs from "./pages/AboutUs";
import ScrollToTop from "./components/ScrollToTop";
import { useState, useLayoutEffect} from 'react';

function DefaultLayout() {
  const [pageJSON, setPageJSON] = useState<object | null>(null)
  useLayoutEffect(() => {
    get_json()
  }, [])

  async function get_json() {
    // return await fetch('/example.json')
    return await fetch('https://r2.shengacademy.com/page-content.json')
      .then(res => res.json())
      .then((data) => {
        setPageJSON(data)
    });
  }

  return (
    <div>
      <Navbar/>
      <ScrollToTop/>
      <Outlet context={pageJSON}/>
      <img src="/images/whatsapp_logo.png" id="whatsapp-contact" onClick={() => {
        window.location.href="https://api.whatsapp.com/send?phone=6598206604"
      }}/>
      <Footer />
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
      {
        path: "",
        element: <Home/>
      },
      {
        path: 'preschool',
        element: <Preschool/>
      },
      {
        path: "secondary",
        element: <Secondary/>
      },
      {
        path: "primary",
        element: <Primary/>
      },
      {
        path: "junior-college",
        element: <JuniorCollege/>
      },
      {
        path: "enrichment-classes",
        element: <Enrichment/>
      },
      {
        path: 'about-us',
        element: <AboutUs/>
      },
      {
        path: 'contact-us',
        element: <ContactUs/>
      },
      { path: 'student-care',
        element: <StudentCare/>
      },
      {
        path: "blog",
        children : [
          {
            path: "",
            element: <Blog/>
          },
          {
            path: ":id",
            element: <BlogPost/>
          }
        ]
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);