import './Carousel.css';
import { Link } from 'react-router-dom';

function Carousel(url_list, url_domain) {
    return ( url_list ?
        <div>
            <div id="image-carousel" className="carousel slide" data-bs-ride="image-carousel">
                <div className="carousel-indicators">
                {url_list.map((imageURL,index) => 
                    <button type="button" data-bs-target="#image-carousel" data-bs-slide-to={index} className = {index == 0 ? "active" : ""} aria-current={index == 0 ? "true" : "false"} aria-label={"Slide "+index}></button>
                )}
                </div>
                <div className="carousel-inner" data-bs-interval="100">
                    {url_list.map((imageURL,index) => 
                        <div className={index == 0 ? "carousel-item active" : "carousel-item"}>
                            <img src={url_domain+imageURL["banner_url"]} className={imageURL["onclick_url"] != "" ? "d-block w-100 carousel-img clickable" : "d-block w-100 carousel-img"} alt="..." onClick={() => {
                                if (imageURL["onclick_url"] != "") {
                                    window.location.href=imageURL["onclick_url"]
                                }
                            }}/>
                        </div>
                    )}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#image-carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#image-carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    : null
    );
};

export default Carousel;